import React, { useRef, useEffect } from "react"
import styles from "./index.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useSelector } from "react-redux"
import BackgroundImage from "gatsby-background-image"
import CTABtn from "../../Button/CTA"
import Explore from "../Home/Explore"
import Join from "../Home/Join"
import Player from "@vimeo/player"
import { useInView } from "react-intersection-observer"
// import VisibilitySensor from "react-visibility-sensor"

export default function BimmerStory({ sanity }) {
  const isChinese = useSelector(state => state.lang.locale) === "tc"
  return (
    <div>
      <SectionIntro isChinese={isChinese} data={sanity} />
      <SectionTwo isChinese={isChinese} data={sanity} />
      <SectionThree isChinese={isChinese} data={sanity} />
      <SectionFour isChinese={isChinese} />
      <SectionFive />
      <SectionSix isChinese={isChinese} data={sanity} />
      <Explore home={sanity.sanityHomePage} />
      <Join home={sanity.sanityHomePage} />
      <CTABtn />
    </div>
  )
}

function SectionIntro({ isChinese, data }) {
  return (
    <section>
      <header className={`${styles.header} relative overflow-hidden md:hidden`}>
        <div className="bg-container absolute w-full h-full top-0 left-0 -z-10">
          <BackgroundImage
            Tag="div"
            className="w-full h-full md:hidden"
            fluid={data.articleimg3.childImageSharp.fluid}
            backgroundColor={`#040e18`}
            loading="eager"
            critical={true}
          />
        </div>
      </header>
      <Img
        fluid={data.articleimg4.childImageSharp.fluid}
        className="hidden md:block"
      />
      <div className="article-container py-40 md:py-20">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="">
              <h2 className="quotes md:text-5xl bold-font mb-10">
                {isChinese
                  ? "BMW「心悅旅程」徵文比賽"
                  : "A Dream Journey ALONG with BMW Story-Writing Competition"}
              </h2>
              <p className="text-5xl md:text-3xl xxl:text-6xl light-font mb-20">
                {isChinese
                  ? "透過「同行 · 心悅旅程」活動，我們希望發掘並分享一系列BMW與生活同行的每種時刻。無論您現在身在何處，您的故事都值得被分享。BMW「心悅旅程」徵文比賽已於6月22日完滿結束，感謝各參加者的支持。"
                  : "With the ALONG WITH BMW Campaign, we hope to build our inspiring collection of stories. We believe YOU have a unique story to share. A Dream Journey ALONG with BMW Story-Writing Competition has successfully ended on June 22. Thank you to all participants for their supports. "}
              </p>
              <p className="text-5xl md:text-3xl xxl:text-6xl light-font">
                {isChinese
                  ? "立即細閱得獎者與BMW一同經歷的精彩旅程故事！"
                  : "Read the BMW stories of our selected winners now!"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionTwo({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20 my-40 md:my-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <h3 className="quotes quotes--sm md:text-4xl bold-font text-right md:text-left md:mb-20">
                  {isChinese
                    ? "1. 梁小姐與BMW 520i的故事"
                    : "1. The Story of Ms. Leung and BMW 520i"}
                </h3>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <p className="large-text light-font mb-20">
                  {isChinese
                    ? "過去的三年，BMW見證了我和現任老公最重要時刻。三年前我認識了我現任老公，第一次約會，就是駕駛116i接載我去吃夜宵。"
                    : "In the past three years, BMW has witnessed the most important moment of me and my husband. I met my husband three years ago and we went for late-night supper with BMW116i during our first date."}
                </p>
                <p className="large-text light-font">
                  {isChinese
                    ? "拍拖一年後便認定了對方，還記得他求婚時，把花及氣球佈置了整個車尾箱，真的非常驚喜，紀憶還很猶新。"
                    : "After dating for a year, he proposed to me. I still remember how he decorated the car and surprised me with flowers and balloon. My memory of the proposal is still fresh in my minds."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionThree({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="md:hidden">
              <Img fluid={data.articleimg5.childImageSharp.fluid} />
            </div>
            <div className="hidden md:flex md:flex-col md:-mx-12">
              <div className="md:w-full">
                <Img fluid={data.articleimg.childImageSharp.fluid} />
              </div>
              <div className="md:w-full md:mt-12">
                <Img fluid={data.articleimg2.childImageSharp.fluid} />
              </div>
            </div>
            <div className="text-center my-40 md:my-20">
              <div className="flex justify-center">
                <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
              </div>
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20">
                {isChinese
                  ? "「遇上BMW是一種緣份吧，我會繼續與老公、狗狗及BMW開展我們的新生活，向Happily Ever After進發。」"
                  : " “During the ceremony, we used the BMW520i as the main float. We decorated the float with the theme ‘Happily Ever After’.”"}
              </h3>
              <p className="large-text light-font text-center leading-relaxed">
                {isChinese
                  ? ""
                  : "Ms. Leung, A Dream Journey ALONG with BMW Story-Writing Competition Winner"}
              </p>
            </div>
            <div className="">
              <p className="large-text">
                {isChinese
                  ? "婚後，老公希望我和小乘客狗狗坐得舒適一點，剛剛換了BMW GT328i。"
                  : "After marriage, we decided to go for BMW328i as my husband wanted to give me and puppy a more comfortable driving experience."}
              </p>
            </div>
            <div className="text-center my-40 py-64 md:py-32 md:my-20">
              <div className="flex justify-center">
                <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
              </div>
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20">
                {isChinese
                  ? "「由拍拖、求婚到結婚，BMW都陪伴著我們。」"
                  : " “From dating to marriage, BMW has accompanied us along the way. BMW will continue to guide us along the way to ‘Happily Ever After’.”"}
              </h3>
              <p className="large-text light-font text-center leading-relaxed">
                {isChinese
                  ? "梁小姐"
                  : "Ms. Leung, A Dream Journey ALONG with BMW Story-Writing Competition Winner"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFour({ isChinese }) {
  return (
    <section className="py-40 md:py-20 bg-brand-light-grey">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="flex md:flex-col">
              <div className="w-1/2 md:w-full pr-20 md:pr-0">
                <div className="flex justify-end">
                  <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
                </div>
                <h3 className="quotes quotes--sm md:text-4xl bold-font text-right md:text-left md:mb-20">
                  {isChinese
                    ? "2. 許先生與BMWX4的故事"
                    : "2. The Story of Mr. Hui with BMW X4"}
                </h3>
              </div>
              <div className="w-1/2 md:w-full pl-20 md:pl-0">
                <p className="large-text light-font">
                  {isChinese
                    ? "疫情令孩子3個月也不能外出，坐交通工具擔心人多擠逼。"
                    : "The epidemic prevented our family from going out for three months. I was worried about going out and taking public transportation."}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionFive() {
  const video = useRef(null)
  const [ref, inView, entry] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (video.current) {
      const player = new Player(video.current, {
        id: "https://vimeo.com/441259159/6d4d737f87",
        responsive: true,
        autoplay: false,
        muted: true,
        loop: true,
      })
      if (inView) {
        player.play()
      } else {
        player.pause()
      }
    }
  })
  return (
    <section className="pt-40 md:pt-20" ref={ref}>
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="px-48 mb-32 md:px-0">
              <div ref={video}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SectionSix({ isChinese, data }) {
  return (
    <section className="py-40 md:py-20">
      <div className="article-container">
        <div className="flex justify-center">
          <div className="w-10/12 md:w-full px-12">
            <div className="text-center">
              <div className="flex justify-center">
                <hr className="w-64 md:w-full h-1 bg-black mb-32 md:hidden" />
              </div>
              <h3 className="quotes quotes--sm md:text-4xl bold-font mb-20">
                {isChinese
                  ? "「我終於下定決心買一直喜歡的BMW X4，與他們開展一趟童話之旅。」"
                  : "“And therefore, I finally made up my mind to purchase the BMW X4, and begin the new journey with my family.”"}
              </h3>
              <p className="large-text light-font text-center leading-relaxed">
                {isChinese
                  ? "許先生"
                  : "Mr. Hui, A Dream Journey ALONG with BMW Story-Writing Competition Winner"}
              </p>
            </div>
            <div className="my-40 md:my-20">
              <p className="large-text mb-20">
                {isChinese
                  ? "孩子坐在自己的車上很安心，又不用戴口罩那麼焗促。窗外青馬大橋的景色一覽無遺，輕輕鬆鬆20分鐘就抵達迪士尼酒店，在天窗上更看到藍藍的天空。酒店外有一大片草地，停下來讓孩子在草地上奔跑、吹泡泡，在小路上踩滑板車，呼吸新鮮空氣。"
                  : "We do not need to wear a mask on our car and can be rest assured that it is safe. During our journey to Hong Kong Disneyland, we can enjoy the beautiful scenery of Tsing Ma Bridge along the way. BMW accompanies us with a point-to-point self-driving experience."}
              </p>
              <p className="large-text">
                {isChinese
                  ? "回程途中，在舒適的座椅上，他們不到5分鐘已呼呼大睡，看來很滿意這次旅程。"
                  : "With only 20 minutes’ drive, we arrived in the Hong Kong Disneyland Hotel. The kids can play around in the large lawn outside the hotel, ride the scooter and have a breath of fresh air. On the way back home, my family fell asleep in the car and they enjoyed the whole journey with BMW."}
              </p>
            </div>
            <hr className="h-1 w-full bg-black opacity-25"/>
            <div className="py-64 md:py-20 mt-64 md:mt-20 md:mb-0">
              <p className="large-text mb-20">
                {isChinese
                  ? "人人均可啓動心悅旅程，期待與您在BMW的旅途上遇見。立即參加 #MYBMWDAY Hashtag 活動，回顧您與BMW的感動時刻。"
                  : "We invite every one of you to build our inspiring collection of stories by sharing some aspect of your journey to empowerment with BMW. Join the #MYBMWDAY Hashtag Campaign and throwback to your BMW moments. "}
              </p>
              <div className="btn-container mt-16">
                <Link
                  to={isChinese ? "/independence" : "/en/independence"}
                  target="_blank"
                  className="px-8 py-4 border border-solid border-black inline-block hover:bg-black hover:text-white duration-300 transition-all ease-out"
                >
                  {isChinese
                    ? "了解更多有關 #MYBMWDAY Hashtag 活動"
                    : "Learn more about #MYBMWDAY Hashtag Campaign"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
